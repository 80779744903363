<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="container pr-0">
            <div class="row pr-4">
                <div class="xs10">
                    <div
                        class="row align-center"
                        id="label_BCHoatDongXeTimeline_BienKiemSoat"
                    >
                        <span class="font-24 font-bold mr-3"
                            >Biển kiểm soát:</span
                        >
                        <DxSelectBox
                            v-model="BienKiemSoat"
                            :dataSource="{
                                store: data_from_api.DsBienKiemSoat,
                                paginate: true,
                            }"
                            displayExpr="BienKiemSoat"
                            valueExpr="IdXe"
                            :searchEnabled="true"
                            stylingMode="outlined"
                            placeholder
                            id="dropdown_BCHoatDongXeTimeline_BienKiemSoat"
                            width="160"
                            height="36"
                        ></DxSelectBox>
                        <span class="mr-3 ml-4 font-16">Từ</span>
                        <DxDateBox
                            v-model="TuNgay"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            :onContentReady="onDateBoxContentReady"
                            height="36"
                            width="150"
                            :onPaste="onPaste"
                            id="datebox_BaoCaoHDBenXeBieuDo_TuNgay"
                            validationMessageMode="always"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRangeRule
                                    :max="DenNgay"
                                    message="Từ ngày không được lớn hơn Đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <div>
                                    <DxButton icon="mdi mdi-calendar" />
                                </div>
                            </template>
                        </DxDateBox>
                        <span class="mx-3 font-16" style="letter-spacing: -1px"
                            >----</span
                        >
                        <DxDateBox
                            v-model="DenNgay"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            :onContentReady="onDateBoxContentReady"
                            height="36"
                            width="150"
                            :onPaste="onPaste"
                            id="datebox_BaoCaoHDBenXeBieuDo_DenNgay"
                            validationMessageMode="always"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Đến ngày không được bỏ trống"
                                />
                                <!-- <DxRangeRule
                                    :min="TuNgay"
                                    message="Đến ngày không được nhỏ hơn từ ngày"
                                />-->
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            id="button_BaoCaoHDBenXeBieuDo_LapBieuDo"
                            icon="search"
                            styling-mode="outlined"
                            :rtlEnabled="true"
                            class="ml-4"
                            text="Lọc"
                            height="36"
                            type="normal"
                            @click="getData()"
                        />
                    </div>
                    <div
                        class="row font-20 font-regular mt-2"
                        id="label_BCHoatDongXeTimeline_DonViVanTai"
                    >
                        {{ TenDVVT }}
                    </div>
                    <div
                        class="row font-16 font-medium mt-1"
                        id="label_BCHoatDongXeTimeline_Soghe_Giuong"
                    >
                        Số ghế | số giường: {{ SoGheSoGiuong }}
                    </div>
                </div>
                <div class="xs2">
                    <div class="row justify-end">
                        <div class="fill-height ml-4">
                            <DxButton
                                icon="mdi mdi-format-list-checkbox"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                class="color-primary"
                            />
                            <DxButton
                                icon="mdi mdi-chart-timeline-variant"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                @click="XemDangTimeLine()"
                            />
                            <DxButton
                                icon="mdi mdi-cog"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                @click="openCaiDatHienThi = true"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <danh-sach-chi-tiet-vue :Params="data_from_api" />
        </div>
        <!-- <BaoCaoHoatDongCuaXe
            :data_from_api="data_from_api"
            :Loadding="Loadding"
            :BienKiemSoat="BienKiemSoat"
            :NgayGioHeThong="NgayGioHeThong"
            :isLoc="isLoc"
            :isScroll="isScroll"
            :NgayLoc="NgayLoc"
            ref="comBaoCaoHoatDongCuaXe"
            :openCaiDatHienThi="openCaiDatHienThi"
            @hiddenDialogCaiDat="hiddenDialogCaiDat"
            @LayThemDuLieu="LayThemDuLieu"
        />-->

        <!-- Loadding -->
        <!-- <LoaddingVue :Model="Loadding" /> -->
    </div>
</template>
<script>
import router from "@/components/_Common/Components/router";
import LoaddingVue from "@/components/_Common/Components/Loadding.vue";

import moment from "moment";
import DanhSachChiTietVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaXe/DanhSachChiTiet.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxSelectBox, DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";

export default {
    // Permission: $PermText("DanhSachTheoXe", "BCHoatDongXe"),
    layout: "application",
    components: {
        router,
        LoaddingVue,
        DanhSachChiTietVue,
        DxToolbar,
        DxItem,
        DxSelectBox,
        DxValidator,
        DxRangeRule,
        DxButton,
        DxDateBox,
        DxRequiredRule,
    },
    data() {
        return {
            url: this.$i18n.t("url.BaoCaoHoatDongTheoXeList"),
            url_NgayGioHeThong: this.$i18n.t("url.NgayGioHeThong"),
            url_DsBienKiemSoat: this.$i18n.t(
                "url.ChiTietBCHoatDongXeDanhSachBienKiemSoatXe"
            ),

            id_table: "IdBaoCao",
            openCaiDatHienThi: false,
            BienKiemSoat: null,
            NgayHoatDong: null,
            TenDVVT: null,
            SoGheSoGiuong: null,
            openDateBox: false,
            Loadding: false,
            NgayLoc: null,
            isLoc: false,
            isScroll: false,
            TuNgay: null,
            DenNgay: null,
            data_from_api: {
                Data: null,
                DsBienKiemSoat: [],
                DsChiTiet: [],
            },
            NgayGioHeThong: null,
            breadcrumbsItems: [
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoThongKe",
                    text: "Báo cáo - thống kê",
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoHoatDongXe",
                    text: "Báo cáo hoạt động của xe",
                    disabled: false,
                    to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/",
                },
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoHoatDongXe",
                    text: "Chi tiết thông tin",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        getRandomColor() {
            var letters = "0123456789ABCDEF";
            var color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        hiddenDialogCaiDat() {
            this.openCaiDatHienThi = false;
        },
        onDateBoxContentReady(e) {
            // console.log("onDateBoxContentReady -> e", e.component.option());
            // e.component.option("inputAttr", { readonly: true });
        },
        keyDown(e) {
            const str = event.key || String.fromCharCode(event.which);
            if (!/^[0-9]*$/.test(str)) {
                e.event.preventDefault();
            }
        },
        onPaste(e) {
            e.event.preventDefault();
        },

        // Hàm lấy dữ liệu ban đầu
        async LayDuLieuBanDau() {
            this.$startLoading;
            this.data_from_api.Data = null;

            // Lấy giờ hệ thống
            let NgayGioHeThong = await this.$Core.Api.BaoCaoBenXe(
                this.url_NgayGioHeThong
            ).Get();
            this.NgayGioHeThong = NgayGioHeThong.Data.Data;
            if (!!this.NgayHoatDong) {
                this.TuNgay = this.NgayHoatDong;
                this.DenNgay = this.NgayHoatDong;
            } else {
                this.TuNgay = NgayGioHeThong.Data.Data;
                this.DenNgay = NgayGioHeThong.Data.Data;
            }
            // Lấy ds biển kiểm soát
            let DsBienKiemSoat = await this.$Core.Api.BaoCaoBenXe(
                this.url_DsBienKiemSoat
            ).Get();
            this.data_from_api.DsBienKiemSoat = DsBienKiemSoat.Data.Data;
            this.NgayHoatDong = null;
            this.getData();
        },
        async getData() {
            try {
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(this.TuNgay),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.DenNgay),
                    idSos: 0,
                    idBens: 0,
                    idDonVis: 0,
                    txtTimKiem: "",
                    idXe: this.BienKiemSoat,
                };
                this.data_from_api.DsChiTiet = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_DanhSachChiTietHoatDongCuaXe",
                        query
                    )
                ).Store;
            } catch (error) {
                console.log(error);
            }
        },
        XemDangTimeLine() {
            localStorage.setItem("BienKiemSoat", this.BienKiemSoat);
            localStorage.setItem("NgayHoatDong", this.DenNgay);
            this.$router.push(
                "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Chi-Tiet"
            );
        },
    },
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
        this.BienKiemSoat = parseInt(localStorage.getItem("BienKiemSoat"));
        this.NgayHoatDong = localStorage.getItem("NgayHoatDong");
        this.LayDuLieuBanDau();
        localStorage.removeItem("BienKiemSoat");
        localStorage.removeItem("NgayHoatDong");
    },
};
</script>


<style scoped>
.custom-icon {
    border-radius: 4px !important;
}
>>> .color-primary i {
    color: #006fff;
}
</style>