<template>
    <DxDataGrid
        id="table_BaoCaoHDXe_DanhSachChiTiet"
        class="table-page mt-5"
        :show-borders="true"
        :remote-operations="true"
        :allow-column-resizing="true"
        :data-source="Params.DsChiTiet"
        :column-auto-width="true"
        key-expr="IdBaoCao"
        height="calc(100vh - 56px - 48px - 68px - 32px)"
        @cellHoverChanged="onCellHoverChanged"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <DxScrolling mode="virtual" row-rendering-mode="virtual" />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn
            :allowFiltering="false"
            :allowSorting="true"
            :fixed="true"
            data-field="STT"
            caption="STT"
            width="56"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            :allowSorting="true"
            :fixed="true"
            data-field="NgayHoatDong"
            caption="Ngày hoạt động"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            :allowSorting="true"
            :fixed="true"
            data-field="TenTuyen"
            caption="Tuyến vận chuyển"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            :allowSorting="true"
            data-field="GuidnhatTrinhXe"
            caption="Mã nhật trình"
        ></DxColumn>
        <DxColumn
            :allowFiltering="true"
            :allowSorting="true"
            data-field="HinhThucChay"
            caption="Hoạt động vận chuyển"
        ></DxColumn>
        <DxColumn
            :allowFiltering="true"
            :allowSorting="true"
            data-field="TrangThaiDuDieuKien"
            caption="Điều kiện xuất bến"
        ></DxColumn>
        <DxColumn caption="Thông tin giấy tờ" alignment="center">
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="HanDangKiem"
                format="dd-MM-yyyy"
                data-type="date"
                caption="Hạn đăng kiểm"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                format="dd-MM-yyyy"
                data-type="date"
                data-field="HanBaoHiem"
                caption="Hạn bảo hiểm"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="HanPhuHieu"
                format="dd-MM-yyyy"
                data-type="date"
                caption="Hạn phù hiệu"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Nhân viên nhà xe" alignment="center">
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field=""
                caption="Lái xe 1"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field=""
                caption="Lái xe 2"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Bến đi" alignment="center">
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiTenBen"
                caption="Tên bến đi"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="NvCapPhepLenNot"
                caption="Nhân viên cấp phép lên nốt"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiGioDenBen"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ vào bến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiGioCapLenNot"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ cấp lên nốt"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiGioCapLenhXuatBen"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ cấp lệnh xuất bến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiGioXuatBenKeHoach"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ XB kế hoạch"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiGioXuatBenThucTe"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ XB thực tế"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="ThoiGianTruyenTaiXuatBen"
                caption="Giờ truyền tải dữ liệu"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="DoTreTruyenTaiXuatBen"
                caption="Độ lệch giờ truyền tải"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDiSoKhach"
                caption="Số khách"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Bến đến" alignment="center">
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDenTenBen"
                caption="Tên bến đến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="NvXacNhanDenBen"
                caption="Nhân viên xác nhận trả khách"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDenGioDenBen"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                caption="Giờ đến bến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="NvCapLenhXuatBen"
                caption="Nhân viên cấp lệnh xuất bến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                format="HH:mm dd-MM-yyyy"
                data-type="date"
                data-field="BenDenGioXacNhanTraKhach"
                caption="Giờ xác nhận trả khách"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="BenDenSoKhach"
                caption="Số khách"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Giám sát hành trình" alignment="center">
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="GshtTocDoTrungBinh"
                caption="Tốc độ trung bình (Km/h)"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                :allowSorting="true"
                data-field="GshtTocDoCaoNhat"
                caption="Tốc độ cao nhất (Km/h)"
            ></DxColumn>
        </DxColumn>
        <DxColumn
            :allowFiltering="false"
            :allowSorting="true"
            data-field="GhiChu"
            caption="Ghi chú"
        ></DxColumn>
    </DxDataGrid>
</template>
<script>
import { DxDataGrid } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
    },
    props: {
        Params: { type: Object, default: () => {} },
    },
    data() {
        return {};
    },
    methods: {
        onCellHoverChanged(e) {
            this.$Helper.Common.table({
                Element: e,
                Buttons: false,
            }).HoverRow();
        },
    },
};
</script>
<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="1"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="2"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="7"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="8"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="9"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="15"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="16"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="17"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="18"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="19"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="20"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="25"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="27"] {
    text-align: center !important;
}
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="22"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="28"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="29"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="30"] {
    text-align: right !important;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>